import request from '../request';

const serviceName = '/smart-site/device';

// 获取塔吊安全监测设备数据 获取升降机监测设备数据
export function deviceSafetyInfo(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/detail/${deviceId}`, {
    method: 'GET',
  });
}
// 获取塔吊运行记录列表
export function deviceRunningRecord(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record/page`, {
    method: 'GET',
    body: data,
  });
}
// 获取塔吊上机记录列表
export function deviceUpRecord(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/on-board/page`, {
    method: 'GET',
    body: data,
  });
}
// 获取吊篮监测设备数据列表 获取卸料平台检测设备数据列表
export function deviceInfo(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/page`, {
    method: 'GET',
    body: data,
  });
}
// 获取吊篮监测设备数据列表
export function deviceStatusData(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record`, {
    method: 'GET',
    body: data,
  });
}
// 获取高支模设备状态列表
export function deviceInfoHF(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/page`, {
    method: 'POST',
    body: data,
  });
}
// 获取污水监测设备数据列表
export function sewageInfo(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/page`, {
    method: 'POST',
    body: data,
  });
}
// 获取高支模单个设备信息
export function hfInfo(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/info/${deviceId}`, {
    method: 'GET',
  });
}
// 获取设备记录列表
export function deviceRecordList(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record/list`, {
    method: 'GET',
    body: data,
  });
}
// 深基坑设备状态 变化趋势 混凝土设备状态
export function deviceRecord(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record`, {
    method: 'GET',
    body: data,
  });
}
// 获取智能地磅过磅列表
export function deviceWbRecord(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/dataList`, {
    method: 'POST',
    body: data,
  });
}
// 获取设备实时数据
export function deviceLiveData(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/detail/${deviceId}`, {
    method: 'GET',
  });
}

//获取高支模编号列表（监测点）
export function getHFUid(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/uidList`, {
    method: 'GET',
    body: data,
  });
}
//获取螺丝松动编号列表（监测点）
export function getCrewLooses(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/getCrewLoosesNumList`, {
    method: 'GET',
    body: data,
  });
}
// 获取螺丝松动设备状态
export function crewLoosesInfo(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/page`, {
    method: 'POST',
    body: data,
  });
}
// 获取螺丝松动设备数据
export function crewLoosesStatusData(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record`, {
    method: 'GET',
    body: data,
  });
}
//获取深基坑监测点 混凝土
export function getPitPonint(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record/monitoringPoint`, {
    method: 'GET',
    body: data,
  });
}
//获取深基坑警报监测点
export function getPitPonintAlarm(data, deviceGroup) {
  return request(`${serviceName}/device/${deviceGroup}/position/list`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @Description 设置智能水表阈值
 * @param data
 * @param deviceGroup
 * @returns {*}
 */
export function setWaterThreshold(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/setThreshold`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @Description 获取智能水表的设备状态列表
 * @param data
 * @param deviceGroup
 * @returns {*}
 */
export function deviceWaterInfo(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record/page`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @Description 获取智能烟感的设备状态
 * @param deviceId
 * @param deviceGroup
 * @returns {*}
 */
export function deviceSmokeInfo(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/getStatus/${deviceId}`, {
    method: 'GET',
  });
}

/**
 * @Description 刷新智能烟感的设备状态
 * @param deviceId
 * @param deviceGroup
 * @returns {*}
 */
export function refreshSmokeInfo(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/refreshStatus/${deviceId}`, {
    method: 'GET',
  });
}

/**
 * @Description 获取报警类型
 * @param deviceGroup
 * @returns {*}
 */
export function deviceTypeList(deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/alarm/subtype/list`, {
    method: 'GET',
  });
}
/**
 * @Description 设置混凝土阈值
 * @param data
 * @param deviceGroup
 * @returns {*}
 */
export function setCtThreshold(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/threshold`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @Description 获取深基坑全部传感器类型
 * @param deviceGroup
 * @returns {*}
 */
export function pitTypeList(deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/monitoringType`, {
    method: 'GET',
  });
}

// 获取设备详情状态数据
export function getStatusData(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record`, {
    method: 'GET',
    body: data,
  });
}
