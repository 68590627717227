import {
  projectList,
  deviceListById,
  getPlanData,
  setPlanData,
} from '@/services/device-manage/device';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';

/**
 * @name 平面图配置MIXIN
 * @description 必要的ref：canvasRef，deviceSelectRef
 */
@Component
export default class PlanViewConfigMixin extends Vue {
  @Prop({ type: [Number, String], required: true }) deviceGroup;

  form = {
    projectId: undefined,
    deviceId: undefined,
    planUrl: undefined,
    planData: undefined,
  };

  rules = {
    projectId: [{ required: true, message: '请选择项目' }],
    deviceId: [{ required: true, message: '请选择设备' }],
    planUrl: [{ required: true, message: '请上传平面图' }],
  };

  /** @name 项目关联设备下拉选项 */
  async deviceOptions() {
    const res = await deviceListById(this.form.projectId, this.deviceGroup);
    return res.map(({ deviceName, deviceId }) => ({
      label: deviceName,
      value: deviceId,
    }));
  }

  /** @name 项目下拉选项 */
  originProjectList = [];
  async projectOptions() {
    const res = await projectList();
    this.originProjectList = res;
    return res.map(({ projectName, pkId }) => ({
      label: projectName,
      value: pkId,
    }));
  }
  canvasBgImg = '';
  spinning = false;
  // 用于缓存项目配置
  configMap = new Map();
  async getPicUrl(ossPath) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const picUrl = await customService.getAuth(ossPath);
    return picUrl;
  }
  /**
   * 找到删除或者所属项目发生改变的设备
   * @param { Object } config
   * @param { Object } config.planData
   * @param { Array<String> } config.deviceId
   */
  findDeviceChange(config) {
    const list = this.$refs.deviceSelectRef.options;
    // 找到已保存配置中被删除或者更换过项目的设备
    const ids = [];
    for (const deviceId of config.deviceId) {
      if (list.find(({ value }) => value === deviceId) === undefined) {
        ids.push(deviceId);
        config.deviceId = config.deviceId.filter(id => deviceId !== id);
      }
    }
    if (ids.length > 0) {
      this.delCanvasEl(ids, config.planData.children);
      setTimeout(this.getValue);
    }
  }

  // 根据设备ID递归删除元素
  delCanvasEl(ids, data) {
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      if (
        item.attrs.name === 'DeviceItem' &&
        ids.includes(item.attrs.data.deviceId)
      ) {
        data.splice(index, 1); // 删除元素
      }
      if (item.children) this.delCanvasEl(ids, item.children);
    }
  }

  async initConfig(config) {
    this.findDeviceChange(config);
    this.form.deviceId = config.deviceId;
    this.form.planUrl = config.planUrl;
    this.form.planData = config.planData;
    this.canvasBgImg = config.planUrl
      ? await this.getPicUrl(config.planUrl)
      : '';
    config.planData
      ? this.$refs.canvasRef.importData(config.planData)
      : this.$refs.canvasRef.initCanvas();
  }
  async projectChange(value) {
    try {
      this.spinning = true;
      const config =
        this.configMap.get(value) ??
        (await getPlanData(value, this.deviceGroup)); // 如果Map中存在就不发起请求
      this.configMap.set(value, config);
      await this.$refs.deviceSelectRef.initOptions();
      this.initConfig(config);
      this.spinning = false;
    } catch (error) {
      this.spinning = false;
    }
  }

  handleSelect({ value, label }) {
    this.$refs.canvasRef.createTower(value, label);
  }

  handleDeSelect({ value }) {
    this.$refs.canvasRef.removeGroupByName(value);
  }

  handleUploadSuccess({ url }) {
    this.canvasBgImg = url;
    this.$refs.canvasRef.changeBackground(url);
  }

  async getValue() {
    await this.$refs.formRef.validate();
    try {
      this.form.planData = this.$refs.canvasRef.exportJson();
      await setPlanData(this.form, this.deviceGroup);
      this.$message.success('保存成功');
      return true;
    } catch {
      return false;
    }
  }
}
