<template>
  <a-upload
    :file-list="fileList"
    :before-upload="beforeUpload"
    :custom-request="handleUpload"
    @change="handleImgsChange"
    v-bind="$attrs"
  >
    <a-spin :spinning="loading">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      <slot>
        <a-button type="primary">
          {{ $t('videoMonitor.addPicConcent') }}
        </a-button>
      </slot>
    </a-spin>
  </a-upload>
</template>

<script>
import { crossStorage } from '@triascloud/cross-storage';
import { UploadService } from '@triascloud/services';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component()
export default class OssUpload extends Vue {
  @Prop({ type: Number, default: 20 }) maxSize; // 图片不能大于20M
  @Prop({ type: String, default: '' }) value;

  fileList = [];
  beforeUpload(file) {
    if (file.size > this.maxSize * 1024 * 1024) {
      this.$message.error(this.$t(`图片不能超过${this.maxSize}M`));
      return false;
    }
    return true;
  }

  handleImgsChange({ fileList }) {
    this.fileList = fileList;
  }

  loading = false;
  async handleUpload({ file, onSuccess }) {
    this.loading = true;
    try {
      const path = await this.uploadImg({
        file,
        uploadPath: '/digital/tower',
      });
      const url = await this.getPicUrl(path);
      this.$emit('success', { path, url });
      this.$emit('input', path);
      onSuccess({ path, url });
    } catch (error) {
      return error;
    }
    this.loading = false;
  }

  tenantId = JSON.parse(crossStorage.getItem('tenant')).pkId;
  async uploadImg({ file, basePath = '/oss/iot/oss', uploadPath }) {
    if (!uploadPath) return;
    const customService = new UploadService(basePath);
    const ossPath = await customService.upload(
      file,
      this.tenantId + uploadPath,
    );

    return ossPath;
  }

  async getPicUrl(ossPath) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const picUrl = await customService.getAuth(ossPath);
    return picUrl;
  }
}
</script>

<style lang="less" module></style>
