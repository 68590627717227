<template>
  <div :class="$style.content">
    <span v-if="showScreen">{{ $t('safety.screen') }}：</span>
    <a-select
      v-if="showType"
      v-model="form.type"
      style="width: 200px;margin-right: 10px"
      allowClear
      :placeholder="placeholder ? placeholder : $t('safety.allType')"
      :getPopupContainer="triggerNode => triggerNode.parentNode"
      @change="handleTypeSubmit"
    >
      <a-select-option
        v-for="(item, idx) in typeOptions"
        :key="idx"
        :value="item.value"
      >
        {{ item.label }}
      </a-select-option>

      <template slot="notFoundContent">
        <a-empty :image="simpleImage" />
      </template>
    </a-select>
    <a-range-picker
      v-if="showDateRange"
      style="width: 380px"
      :show-time="{
        defaultValue: [
          $moment('00:00:00', 'HH:mm:ss'),
          $moment('23:59:59', 'HH:mm:ss'),
        ],
      }"
      format="YYYY-MM-DD"
      @ok="val => changeTimeRange(val, 'ok')"
      @change="val => changeTimeRange(val, 'clear')"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Empty as EmptyObj } from 'ant-design-vue';

@Component()
export default class ScreenSelect extends Vue {
  @Prop({ type: Boolean, default: true }) showType;
  @Prop({ type: Boolean, default: false }) showSensor;
  @Prop({ type: Boolean, default: true }) showDateRange;
  @Prop({ type: Boolean, default: true }) showScreen;
  @Prop({ type: Array, default: () => [] }) typeOptions;
  @Prop({ type: String, default: '' }) placeholder;
  simpleImage;
  beforeCreate() {
    this.simpleImage = EmptyObj.PRESENTED_IMAGE_SIMPLE;
  }
  form = {
    type: undefined,
  };
  @Watch('typeOptions', { immediate: true, deep: true })
  changeType(newVal) {
    if (newVal && newVal.length > 0) {
      this.typeOptions = newVal;
    }
  }
  handleTypeSubmit(val) {
    const idx = this.typeOptions.findIndex(v => v.value === val);
    const obj = {
      type: this.typeOptions[idx]?.typeEnum || '',
      value: val,
    };
    this.$emit('changeType', obj);
  }
  changeTimeRange(time, flag) {
    if (flag === 'ok') {
      const startTime = this.$moment(time[0]).valueOf();
      const endTime = this.$moment(time[1]).valueOf();
      const t = {
        startTime,
        endTime,
      };
      this.$emit('changeTimeRange', t);
    } else {
      if (time.length === 0) {
        this.$emit('changeTimeRange', []);
      }
    }
  }
}
</script>

<style lang="less" module>
.content {
  margin-bottom: 10px;
}
</style>
