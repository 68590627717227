import { Vue, Component, Prop } from 'vue-property-decorator';
import Konva from 'konva';
import { planViewEnum } from './enum';

/**
 * @name 平面图MIXIN
 * @description 必要的ref：canvasRef，deviceSelectRef
 */
@Component
export default class PlanViewCanvasMixin extends Vue {
  @Prop({ type: String, default: '' }) background;

  initRadius = 100;
  /**@readonly */
  canvasWidth = 0;
  /**@readonly */
  canvasHeight = 0;
  /**@type { Konva.Stage } */
  stage = null;
  /**@type { Konva.Transformer } */
  tr = null;
  /**@type { Konva.Layer } */
  layer = null;
  ratio = 16 / 9;
  scale = 1;
  defaultRatio = 16 / 9;

  // 更换背景图
  changeBackground(url) {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      const { width, height } = image;
      const ratio = width / height;
      this.setRatio(ratio);
      this.computedCanvasSize();
      this.resizeStage();
    };
  }

  computedCanvasSize() {
    const {
      width,
      height,
    } = this.$refs.konvaRef.parentElement.getBoundingClientRect();
    const boxRatio = width / height;
    this.canvasWidth = width;
    this.canvasHeight = width / this.ratio;
    if (this.ratio < boxRatio) {
      this.canvasWidth = height * this.ratio;
      this.canvasHeight = height;
    }
  }

  initCanvas() {
    this.setRatio();
    this.computedCanvasSize();
    this.stage = new Konva.Stage({
      container: this.$refs.konvaRef,
      width: this.canvasWidth,
      height: this.canvasHeight,
    });

    this.layer = new Konva.Layer({ name: 'shapeLayer' });
    this.stage.add(this.layer);

    if (this.planViewType === planViewEnum.towerCrane) {
      // 添加变形
      this.tr = new Konva.Transformer({
        keepRatio: true,
        centeredScaling: true,
        rotationSnaps: [0, 90, 180, 270],
        enabledAnchors: [
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right',
        ],
      });
      this.layer.add(this.tr);
      this.handleStageEvent();
    }
  }

  handleStageEvent() {
    this.stage.on('click tap', e => {
      // if click on empty area - remove all selections
      if (e.target === this.stage) {
        this.tr.nodes([]);
        return;
      }

      // do nothing if clicked NOT on our rectangles
      if (
        !e.target
          .getParent()
          .name()
          .includes('DeviceItem')
      ) {
        return;
      }

      const selectGroup = e.target.getParent();

      selectGroup.moveToTop();

      // do we pressed shift or ctrl?
      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      const isSelected = this.tr.nodes().indexOf(selectGroup) >= 0;

      if (!metaPressed && !isSelected) {
        // if no key pressed and the node is not selected
        // select just one
        this.tr.nodes([selectGroup]);
      } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = this.tr.nodes().slice(); // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(selectGroup), 1);
        this.tr.nodes(nodes);
      } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = this.tr.nodes().concat([selectGroup]);
        this.tr.nodes(nodes);
      }
    });
  }

  importData(data) {
    this.destroy();
    this.stage = Konva.Node.create(data, this.$refs.konvaRef);
    this.layer = this.stage.findOne('.shapeLayer');
    this.handleGroupEvent();
    if (this.planViewType === planViewEnum.towerCrane) {
      this.tr = this.stage.findOne('Transformer');
      this.handleStageEvent();
    }
  }

  handleGroupEvent() {
    this.stage.find('.DeviceItem').forEach(group => {
      group.on('mouseenter', () => {
        document.body.style.cursor = 'grab';
      });

      group.on('mouseout', () => {
        document.body.style.cursor = 'default';
      });
    });
  }
  // 创建塔吊
  createTower(deviceId, deviceName) {
    if (!this.stage) return;
    /**@type { Konva.Group } */
    const group = new Konva.Group({
      x: this.canvasWidth / 2,
      y: this.canvasHeight / 2,
      width: this.initRadius * 2,
      height: this.initRadius * 2,
      draggable: true,
      name: `DeviceItem`,
      data: {
        deviceName,
        deviceId,
      },
    });

    // 圆心部分
    const CircleCenter = new Konva.Circle({
      x: 0,
      y: 0,
      radius: this.initRadius * 0.1,
      fill: '#37D7DB',
      stroke: '#fff',
      strokeWidth: this.initRadius * 0.02,
      name: 'CircleCenter',
      listening: this.planViewType !== planViewEnum.towerCrane,
    });

    // 设备名称
    const text = new Konva.Text({
      x: -this.initRadius,
      y: 16,
      text: deviceName,
      fontSize: 16,
      fill: '#fff',
      align: 'center',
      width: this.initRadius * 2,
      lineHeight: 1.5,
      listening: false,
    });

    if (this.planViewType === planViewEnum.towerCrane) {
      // 半径范围
      const CircleRadius = new Konva.Circle({
        x: 0,
        y: 0,
        radius: this.initRadius,
        fill: 'rgba(55, 215, 219, 0.5)',
        stroke: '#37D7DB',
        strokeWidth: this.initRadius * 0.02,
        name: 'CircleRadius',
      });

      // 端点
      const CircleHook = new Konva.Circle({
        x: 0,
        y: -this.initRadius + this.initRadius * 0.05,
        radius: this.initRadius * 0.05,
        fill: '#37D7DB',
        name: 'CircleHook',
        listening: false,
      });

      // 半径
      const radiusLine = new Konva.Line({
        points: [0, 0, 0, -this.initRadius],
        stroke: '#ffffff',
        strokeWidth: 1,
        listening: false,
      });

      group.add(CircleRadius, text, radiusLine, CircleHook, CircleCenter);
      this.tr.nodes([group]);
    } else {
      group.add(text, CircleCenter);
    }
    this.layer.add(group);
    this.layer.draw();

    group.on('mouseenter', () => {
      document.body.style.cursor = 'grab';
    });

    group.on('mouseout', () => {
      document.body.style.cursor = 'default';
    });
  }

  removeAllGroups() {
    const nodes = this.layer.find('.DeviceItem');
    nodes.forEach(node => node.remove());
    if (this.planViewType === planViewEnum.towerCrane) {
      this.tr.nodes([]);
    }
  }

  removeGroupByName(id) {
    const node = this.layer
      .find('.DeviceItem')
      .find(node => node.attrs.data.deviceId === id);
    node.remove();
  }

  destroy() {
    this.stage?.destroy();
  }

  exportJson() {
    return JSON.parse(this.stage.toJSON());
  }

  /**
   * 设置画布比例
   * @param {number} val
   */
  setRatio(val) {
    this.ratio = val ?? this.defaultRatio;
  }

  resizeStage() {
    this.stage.width(this.canvasWidth);
    this.stage.height(this.canvasHeight);
    this.stage.draw();
  }

  async mounted() {
    await this.$nextTick();
    this.initCanvas();
  }
}
