<template>
  <div :class="$style.wrap">
    <span :class="$style.item">
      <ali-icon
        type="tc-icon-a-zaixian-xian1"
        :class="[$style.icon, 'online-color']"
      />{{ $t('greenConstruction.onlineNumber') }}：{{ online }}</span
    >
    <span :class="$style.item"
      ><ali-icon
        type="tc-icon-lixian-xian"
        :class="[$style.icon, 'offline-color']"
      />{{ $t('greenConstruction.offlineNumber') }}：{{ offline }}</span
    >
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon';

@Component({
  components: {
    AliIcon,
  },
})
export default class OnlineOffline extends Vue {
  @Prop({ type: [Number, String], default: '0' }) online;
  @Prop({ type: [Number, String], default: '0' }) offline;
}
</script>
<style lang="less" module>
.wrap {
  display: flex;
  border-left: 1px solid #e2e2e2;
  gap: 10px;
  height: 32px;
  .item {
    display: flex;
    align-items: center;
    padding-left: 20px;
    .icon {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
</style>
